import React, {useMemo} from 'react';

import {ForTheSection} from 'src/components/enterprise/ForTheSection';
import {Hero} from 'src/components/enterprise/Hero';
import {InvestSection} from 'src/components/enterprise/InvestSection';
import {ServicesSection} from 'src/components/enterprise/ServicesSection';
import {StatsSection} from 'src/components/enterprise/StatsSection';
import {Box, SEOWithQuery} from 'src/components/shared';
import {seo} from 'src/utils/copy';

const EnterprisePage: React.FC = ({...props}) => {
  const containerMaxWidth = '80.1875rem';

  return (
    <Box backgroundColor="midnightBlue" color="cream">
      <Hero containerMaxWidth={containerMaxWidth} />
      <ForTheSection containerMaxWidth={containerMaxWidth} />
      <StatsSection containerMaxWidth={containerMaxWidth} />
      <ServicesSection containerMaxWidth={containerMaxWidth} />
      <InvestSection containerMaxWidth={containerMaxWidth} />

      <SEOWithQuery title={seo.enterpriseTitle} />
    </Box>
  );
};

export default EnterprisePage;
